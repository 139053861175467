import { useEffect, useState } from 'react';
import { setupLiff } from '../lib/liff';
import initPages from '../lib/pages';
import { getVendor } from 'src/services/utils';
import { fetchAndSaveAuthToken } from '../services/user.service';
import { setAuth } from 'src/services/utils';
import { t } from 'src/i18n/config';

export default function usePages() {
  const [message, setMessage] = useState({});
  const [token, setToken] = useState();
  const [vendor, setVendor] = useState();
  const [lineProfilePhone, setLineProfilePhone] = useState();
  const [lineProfile, setLineProfile] = useState();

  useEffect(() => {
    const { liffId, userId } = initPages();
    setupLiff().then((res) => {
      setLineProfile(res.lineProfile);
      setLineProfilePhone(res.lineProfilePhone);
      if (userId && liffId) {
        fetchAndSaveAuthToken({ userId, liffId })
          .then((t) => {
            setAuth({ token: t, liffId, userId });
            setMessage({ liffId, userId });
            setVendor(getVendor(t));
            setToken(t);
          })
          .catch((err) => {
            console.log('usePages promise catch', { err });
            alert(`${t('authorizationFailed')}${err.message}`);
          });
      }
    });
  }, []);

  return {
    message,
    token,
    vendor,
    lineProfilePhone,
    lineProfile,
  };
}
