import React, {
  useCallback,
  useMemo,
  useReducer,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import {
  studIdNumberIdentify,
  idTypes,
  genderOptions,
} from '../../../utils/user';
import { deleteMember, updatePatient } from '../../../services/user.service';
import { getURLSearchParamByKey } from 'src/utils';
import { t } from 'src/i18n/config';
import {
  TextField,
  RadioGroup,
  InputContainer,
  DatePicker,
} from '../components/Fields';
import Button from '../components/Button';
import Popup from '../components/Popup';

const InputField = {
  NAME: 'name',
  ID_NUM: 'idNum',
  RELATION: 'relation',
};

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const defaultState = {
  info: {
    name: '',
    birth: '',
    idNum: '',
    relation: '',
    idType: 'twID',
    gender: 'M',
  },
  errInput: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setname':
      return {
        ...state,
        info: {
          ...state.info,
          name: action.payload,
        },
      };
    case 'setidNum':
      return {
        ...state,
        info: {
          ...state.info,
          idNum: (action.payload || '').toUpperCase(),
        },
      };
    case 'setrelation':
      return {
        ...state,
        info: {
          ...state.info,
          relation: action.payload,
        },
      };
    case 'setbirth':
      return {
        ...state,
        info: {
          ...state.info,
          birth: action.payload,
        },
      };
    case 'userChanged':
      return {
        ...state,
        info: {
          ...state.info,
          ...action.payload,
        },
      };
    case 'validate':
      return {
        ...state,
        errInput: action.payload,
      };
    case 'changeType':
      return {
        ...state,
        info: {
          ...state.info,
          idType: action.payload,
        },
      };
    case 'gender':
      return {
        ...state,
        info: {
          ...state.info,
          gender: action.payload,
        },
      };
    default:
      break;
  }
};

const isValid = (errInput) => {
  if (Object.keys(errInput).length !== 0) {
    return false;
  }
  return true;
};

const EditMember = ({ member }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const birthSelectorRef = useRef();
  const showPassport = getURLSearchParamByKey('passport') === 'true';
  const generateMemberID =
    getURLSearchParamByKey('generateMemberID') === 'true';

  const [state, dispatch] = useReducer(
    reducer,
    Object.keys(member).length > 0
      ? { info: member, errInput: {} }
      : defaultState
  );

  const idOptions = useMemo(() => {
    return showPassport
      ? idTypes
      : idTypes.filter(({ value }) => value !== 'pdID');
  }, [showPassport]);

  const selectedIdType = useMemo(() => {
    return idOptions.find(({ value }) => value === state.info.idType) || {};
  }, [idOptions, state.info.idType]);

  const checkValidation = useCallback(
    (idType) => {
      const errInput = {};
      if (
        !generateMemberID &&
        !studIdNumberIdentify(state.info.idNum, idType)
      ) {
        errInput[InputField.ID_NUM] = `${t('enterCorrectIdType')}${
          selectedIdType.label
        }`;
      }
      if (state.info.name === '') {
        errInput[InputField.NAME] = t('nameFormatError');
      }
      if (state.info.relation === '') {
        errInput[InputField.RELATION] = t('relationshipNameFormatError');
      }
      if (state.info.birth === '' || state.errInput['birth']) {
        const birthError = birthSelectorRef.current.validate();
        errInput['birth'] = birthError;
      }
      dispatch({ type: 'validate', payload: errInput });
      return errInput;
    },
    [
      generateMemberID,
      selectedIdType.label,
      state.errInput,
      state.info.birth,
      state.info.idNum,
      state.info.name,
      state.info.relation,
    ]
  );

  const handleUpdate = () => {
    const errInput = checkValidation(state.info.idType);
    if (!isValid(errInput)) return;
    updatePatient({ id: member.id, ...state.info })
      .then(() => {
        navigate('/users/members/success?from=create', { replace: true });
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const handleDelete = () => {
    deleteMember(state.info)
      .then(() => {
        navigate('/users/members/success?from=delete', { replace: true });
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const handleChange = (e) => {
    const changed = e.currentTarget.name;
    dispatch({ type: 'set' + changed, payload: e.currentTarget.value });
  };

  const handleBirthChange = (value) => {
    dispatch({ type: 'setbirth', payload: value });
  };

  const handleIDTypeChange = (idtype) => {
    dispatch({ type: 'changeType', payload: idtype });
  };

  const handleGenderChange = (gender) => {
    dispatch({ type: 'gender', payload: gender });
  };

  useEffect(() => {
    if (member) {
      dispatch({ type: 'userChanged', payload: member });
    }
  }, [member]);

  if (showDeleteModal) {
    return (
      <Popup
        type="warning"
        title={t('deleteDataTitle')}
        subtext={t('deleteDataSubtext', { name: member.name })}
      >
        <Button maxWidth="343px" variant="alert" onClick={handleDelete}>
          {t('deleteDataTitle')}
        </Button>
        <Button
          maxWidth="343px"
          variant="text"
          onClick={() => setShowDeleteModal(false)}
        >
          返回
        </Button>
      </Popup>
    );
  }

  return (
    <InputContainer>
      <TextField
        label="姓名"
        name={InputField.NAME}
        onChange={handleChange}
        placeholder={t('familyMemberName')}
        value={state.info.name}
        error={state.errInput[InputField.NAME]}
      />
      <TextField
        maxWidth="171.5px"
        label="關係"
        name={InputField.RELATION}
        onChange={handleChange}
        placeholder={t('relationshipName')}
        value={state.info.relation}
        error={state.errInput[InputField.RELATION]}
      />
      {!generateMemberID && (
        <RadioContainer>
          <RadioGroup
            options={idOptions}
            defaultValue={state.info.idType}
            onChange={handleIDTypeChange}
          />
          <TextField
            name={InputField.ID_NUM}
            disabled={state.info.idNumLocked}
            onChange={handleChange}
            placeholder={selectedIdType.label}
            value={state.info.idNum}
            error={state.errInput[InputField.ID_NUM]}
          />
        </RadioContainer>
      )}
      {(selectedIdType.value === 'pdID' || generateMemberID) && (
        <RadioGroup
          options={genderOptions}
          defaultValue={state.info.gender}
          onChange={handleGenderChange}
        />
      )}
      <DatePicker
        label="生日"
        value={state.info.birth}
        handleOnchange={handleBirthChange}
        error={state.errInput['birth']}
      />
      <div className="buttons">
        <Button onClick={handleUpdate}>{t('saveUpdates')}</Button>
        <Button onClick={() => setShowDeleteModal(true)} variant="text">
          {t('delete')}
        </Button>
      </div>
    </InputContainer>
  );
};

EditMember.propTypes = {
  onClickCancel: PropTypes.func,
};

EditMember.defaultProps = {
  member: {},
};

export default EditMember;
