import React from 'react';
import { LineContext } from 'components/context';
import UsersBindingInputPage from 'pages/users/binding/input';
import usePages from 'src/customHooks/pages';

export default function Home(props) {
  const { message, token, lineProfilePhone } = usePages();
  console.log('binding page:', { props, message, token, lineProfilePhone });
  if (token) {
    return (
      <LineContext.Provider value={message}>
        <UsersBindingInputPage
          {...props}
          {...message}
          lineProfilePhone={lineProfilePhone}
        ></UsersBindingInputPage>
      </LineContext.Provider>
    );
  }
  return null;
}
